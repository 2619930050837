import React from "react";
import { Grid, IconButton, Typography } from "@mui/material";
import { Info } from "@mui/icons-material";

export default function InfoCard(props) {
  return (
    <Grid
      display={"flex"}
      alignItems={"center"}
      flexDirection={"row"}
      mt={2}
      borderRadius={2}
      p={1}
      bgcolor={"#B8D0F1"}
    >
      <IconButton>
        <Info style={{ color: "#094498" }} />
      </IconButton>
      <Typography
        color={"#094498"}
        component="div"
        dangerouslySetInnerHTML={{ __html: props.text || "" }}
      />
    </Grid>
  );
}
