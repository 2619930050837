import React from "react";
import { Switch } from "@mui/material";
import { styled } from "@mui/material/styles";

export default function CustomSwitch({ check, handleCheck, disabled }) {
  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 35,
    height: 20,
    padding: 0,
    marginLeft: 10,
    marginRight: 10,
    marginTop: 2,

    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 14,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(15px)",
      },
    },
    "& .MuiSwitch-switchBase + .MuiSwitch-track": {
      backgroundColor: "#43b649 !important",
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(15px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#06A561" : "#06A561",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 14,
      height: 14,
      marginTop: 1,
      marginLeft: 1,
      borderRadius: 10,
      transition: theme.transitions.create(["width"], {
        duration: 300,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 10,
      opacity: 1,
      backgroundColor: theme.palette.mode === "dark" ? "#06A561" : "#06A561",
      boxSizing: "border-box",
    },
  }));
  return (
    <AntSwitch disabled={disabled} checked={check} onChange={handleCheck} />
  );
}
