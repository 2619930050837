import React, { useEffect, useState, useCallback } from "react";

import {
  Box,
  Button,
  Drawer,
  Grid,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { useParams } from "react-router-dom";
import moment from "moment";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { getCustomProfitLossData } from "../../../../redux/ProfitandLoss/ProfitandLossSlice";
import {
  // Cell,
  // ColumnDef,
  // Header,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getExpandedRowModel,
} from "@tanstack/react-table";
import "./CustomReportPlayground.css";
import {
  DragIndicator,
  Edit,
  // Edit,
  KeyboardArrowDown,
  KeyboardArrowRight,
  SettingsOutlined,
  SwapHoriz,
  SwapVert,
} from "@mui/icons-material";
import {
  DndContext,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
  closestCenter,
  KeyboardSensor,
} from "@dnd-kit/core";
import { useSortable } from "@dnd-kit/sortable";

import {
  SortableContext,
  rectSortingStrategy,
  horizontalListSortingStrategy,
  arrayMove,
} from "@dnd-kit/sortable";
import SortableGrid from "../../SpreadSheet/components/SortableContainer/SortableGrid/SortableGrid";
import SortableItem from "../../SpreadSheet/components/SortableContainer/SortableItem/SortableItem";
import { restrictToHorizontalAxis } from "@dnd-kit/modifiers";
import { CSS } from "@dnd-kit/utilities";

import _, { uniqueId } from "lodash";
import { getFormulaSymbol } from "../../SpreadSheet/helper";
import { AntSwitch } from "../../SpreadSheet/Expense/Expense";
import Loading from "../../../../components/LoadingIndicator/Loading";
import { ROW_TYPE } from "../../SpreadSheet/defaultValues";
import { getSpreadSheets } from "../../../../redux/SpreadSheet/SpreadSheetSlice";
import { parse } from "mathjs";
import ObjectID from "bson-objectid";
const { RangePicker } = DatePicker;
const COLUMN_TYPES = { CURRENCY: "Currency", PERCENTAGE: "Percentage" };
const COLUMN_DATA_TYPES = {
  XERO: "Xero",
  SPREADSHEET: "Spreadsheet",
  FORMULA: "Formula",
};
const DATE_RANGE_TYPES = {
  MONTH: "Month",
  CUSTOM: "Custom",
};

const CALCULATION_TYPES = ["Add", "Subtract", "Multiply", "Divide"];
const DraggableTableHeader = ({
  header,
  columns,
  className,
  allowColumnReorder,
  handleEditColumn,
}) => {
  const { attributes, isDragging, listeners, setNodeRef, transform } =
    useSortable({
      id: header.column.id,
    });
  const style = {
    opacity: isDragging ? 0.8 : 1,
    position: "relative",
    transform: CSS.Translate.toString(transform), // translate instead of transform to avoid squishing
    transition: "width transform 0.2s ease-in-out",
    whiteSpace: "nowrap",
    width: header.column.getSize(),
    zIndex: isDragging ? 1 : 0,
  };
  return (
    <th
      colSpan={header.colSpan}
      ref={setNodeRef}
      style={style}
      className={className}
    >
      {header.isPlaceholder
        ? null
        : flexRender(header.column.columnDef.header, header.getContext())}
      {header.id !== "name" && !allowColumnReorder && (
        <IconButton
          {...{
            onClick: () => handleEditColumn(header.column.id),
          }}
        >
          <Edit fontSize="small" className="mt-neg-5" />
        </IconButton>
      )}
      {header.id !== "name" && columns.length > 2 && (
        <Button {...attributes} {...listeners}>
          {allowColumnReorder && <DragIndicator />}
        </Button>
      )}
    </th>
  );
};
const DragAlongCell = ({ cell, className }) => {
  const { isDragging, setNodeRef, transform } = useSortable({
    id: cell.column.id,
  });

  const style = {
    opacity: isDragging ? 0.8 : 1,
    position: "relative",
    transform: CSS.Translate.toString(transform), // translate instead of transform to avoid squishing
    transition: "width transform 0.2s ease-in-out",
    width: cell.column.getSize(),
    zIndex: isDragging ? 1 : 0,
  };

  return (
    <td style={style} ref={setNodeRef} className={className}>
      {flexRender(cell.column.columnDef.cell, cell.getContext())}
    </td>
  );
};

const initialSideNavData = {
  name: "",
  type: COLUMN_TYPES.CURRENCY,
  dataSource: COLUMN_DATA_TYPES.XERO,
  dateType: DATE_RANGE_TYPES.MONTH,
  date: { start: moment(), endDate: null },
  spreadsheetId: null,
  formulaList: null,
};

const CustomReportPlayGround = () => {
  const dispatch = useDispatch();
  const { entityId, accountingFirmId } = useParams();
  const [reportData, setReportData] = useState({});
  const date = moment().subtract(1, "month");
  const [actionType, setActionType] = useState("add");
  const [anchorEl, setAnchorEl] = useState(null);
  const [addType, setAddType] = useState("row");
  const [sideNavOpen, setSideNavOpen] = useState(false);
  const [expanded, setExpanded] = React.useState({});
  const [columnOrder, setColumnOrder] = React.useState([]);
  const [hasError, setHasError] = useState(false);
  const [allowColumnReorder, setAllowColumnReorder] = useState(false);
  const [sideNavData, setSideNavData] = useState(
    _.cloneDeep(initialSideNavData)
  );
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
  const sensors1 = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {})
  );
  const [allowSortOrderFormula, setAllowSortOrderFormula] = useState(false);
  const [formulaList, setFormulaList] = useState([]);

  useEffect(() => {
    dispatch(
      getCustomProfitLossData({
        entityId,
        accountingFirmId,
        start: date,
        end: date,
        loadType: "initial",
        source: COLUMN_DATA_TYPES.XERO,
        dateType: DATE_RANGE_TYPES.MONTH,
      })
    );
    dispatch(getSpreadSheets({ accountingFirmId, entityId, search: "" }));
    // eslint-disable-next-line
  }, []);

  const { customPlData, isFetching } = useSelector(
    (state) => state.profitAndLoss
  );
  const { spreadsheets } = useSelector((state) => state.spreadsheet);
  const rowData = (rows) => {
    let arrangedData = [];
    rows.forEach((row) => {
      if (
        row.rowType === "INITIAL" ||
        row.rowType === "FORMULA" ||
        row.rowType === "METRIC"
      ) {
        let subRows = [];
        if (row.rowType === "METRIC") {
          let childrenRows = rows.filter((val) => val.parentId === row.id);
          childrenRows.forEach((child) => {
            if (
              (child.rowType === "ACCOUNT" || child.rowType === "TOTAL") &&
              !child.subParentId
            ) {
              subRows.push(child);
            }
            if (child.rowType === "GROUP") {
              let subChildrenRows = rows.filter(
                (val) => val.subParentId === child.id
              );
              subRows.push({ ...child, subRows: subChildrenRows });
            }
          });
        }
        arrangedData.push({ ...row, subRows });
      }
    });
    return arrangedData;
  };

  const handleEditColumn = (val) => {
    let currentColumnDetails = reportData.columnDetails.find(
      (col) => col.key === val
    );
    setAddType("column");
    setActionType("edit");
    setSideNavData(_.cloneDeep(currentColumnDetails));
    if (currentColumnDetails.dataSource === COLUMN_DATA_TYPES.FORMULA) {
      setFormulaList(_.cloneDeep(currentColumnDetails)?.formulaList);
    }
    setSideNavOpen(true);
  };

  // const handleAddColumns = () => {};

  const getColumnDetail = (customPlData, key) => {
    return key !== "name"
      ? {
          accessorKey: key,
          id: key,
          size: 150,
          value: customPlData?.columns?.[key],
          header: () => <span>{customPlData?.columns?.[key]}</span>,
        }
      : {
          size: 350,
          accessorKey: "name",
          header: ({ table }) => (
            <div style={{ display: "table", paddingTop: "5px" }}>
              Name
              <IconButton
                size="small"
                {...{
                  onClick: table.getToggleAllRowsExpandedHandler(),
                }}
              >
                {table.getIsAllRowsExpanded() ? (
                  <KeyboardArrowDown />
                ) : (
                  <KeyboardArrowRight />
                )}
              </IconButton>
            </div>
          ),
          cell: ({ row, getValue }) => (
            <div
              style={{
                paddingLeft:
                  row.original.rowType === "TOTAL"
                    ? "0rem"
                    : row.original.rowType === "GROUP_TOTAL"
                    ? `2rem`
                    : `${row.depth * 2}rem`,
                paddingTop: "3px",
              }}
            >
              <div style={{ display: "flex" }}>
                <div>{getValue()}</div>
                {row.getCanExpand() && (
                  <IconButton
                    className="mt-neg-7-5"
                    size="small"
                    {...{
                      onClick: row.getToggleExpandedHandler(),
                      style: { cursor: "pointer" },
                    }}
                  >
                    {row.getIsExpanded() ? (
                      <KeyboardArrowDown />
                    ) : (
                      <KeyboardArrowRight />
                    )}
                  </IconButton>
                )}
              </div>
            </div>
          ),
          footer: (props) => props.column.id,
        };
  };

  useEffect(() => {
    if (customPlData) {
      if (customPlData.loadType === "initial") {
        setReportData({
          columns: Object.keys(customPlData.columns).map((key) => {
            return getColumnDetail(customPlData, key);
          }),
          rows: rowData(
            customPlData.rows?.filter(
              (row) => row.name !== " " && row.name !== ""
            )
          ),
          values: customPlData.rows?.filter(
            (row) => row.name !== " " && row.name !== ""
          ),
          columnDetails: customPlData.columnDetails,
        });
        setColumnOrder(Object.keys(customPlData.columns)?.map((key) => key));
      } else {
        if (customPlData.actionType === "add") {
          setReportData({
            columns: [
              ...reportData.columns,
              ...Object.keys(customPlData.columns).map((key) => {
                return getColumnDetail(customPlData, key);
              }),
            ],
            rows: rowData(
              reportData.values.map((row) => {
                let currentRow = customPlData.rows?.find((rowData) => {
                  return (
                    rowData.id === row.id ||
                    (row.rowType === ROW_TYPE.TOTAL ||
                    row.rowType === ROW_TYPE.FORMULA
                      ? rowData.name === row.name
                      : false)
                  );
                });
                return { ...row, ...currentRow };
              })
            ),
            values: [
              ...reportData.values.map((value) => {
                let currentRow = customPlData.rows?.find((rowData) => {
                  return (
                    rowData.id === value.id ||
                    (value.rowType === ROW_TYPE.TOTAL ||
                    value.rowType === ROW_TYPE.FORMULA
                      ? rowData.name === value.name
                      : false)
                  );
                });
                return { ...value, ...currentRow };
              }),
            ],
            columnDetails: [
              ...reportData.columnDetails,
              ...customPlData.columnDetails,
            ],
          });
          setColumnOrder([
            ...columnOrder,
            ...Object.keys(customPlData.columns)?.map((key) => key),
          ]);
        } else {
          setReportData({
            ...reportData,
            columns: _.cloneDeep(reportData.columns).map((col) => {
              let columnId = Object.keys(customPlData.columns)[0];
              if (col.id === columnId) {
                return getColumnDetail(customPlData, columnId);
              } else {
                return col;
              }
            }),
            rows: rowData(
              _.cloneDeep(reportData.values).map((row) => {
                let currentRow = customPlData.rows?.find((rowData) => {
                  return (
                    rowData.id === row.id ||
                    (row.rowType === ROW_TYPE.TOTAL ||
                    row.rowType === ROW_TYPE.FORMULA
                      ? rowData.name === row.name
                      : false)
                  );
                });
                return { ...row, ...currentRow };
              })
            ),
            values: [
              ...reportData.values.map((value) => {
                let currentRow = customPlData.rows?.find((rowData) => {
                  return (
                    rowData.id === value.id ||
                    (value.rowType === ROW_TYPE.TOTAL ||
                    value.rowType === ROW_TYPE.FORMULA
                      ? rowData.name === value.name
                      : false)
                  );
                });
                return { ...value, ...currentRow };
              }),
            ],
            columnDetails: _.cloneDeep(reportData.columnDetails).map(
              (detail) => {
                let currentDetail = customPlData.columnDetails.find(
                  (det) => det.key === detail.key
                );
                return currentDetail ? currentDetail : detail;
              }
            ),
          });
        }
      }
    }
    // eslint-disable-next-line
  }, [customPlData]);

  const table = useReactTable({
    data: reportData.rows,
    columns: reportData.columns,
    state: {
      expanded,
      columnOrder,
    },
    onColumnOrderChange: setColumnOrder,
    onExpandedChange: setExpanded,
    getSubRows: (row) => row.subRows,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });
  const handleMenuItemClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuItemClose = () => {
    setAnchorEl(null);
  };
  const handleCloseSideNav = () => {
    setSideNavOpen(false);
    setFormulaList([]);
    setSideNavData(_.cloneDeep(initialSideNavData));
    setAllowSortOrderFormula(false);
    setHasError(false);
  };

  const handleDragEnd = useCallback((event) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      if (over?.id) {
        setFormulaList((items) => {
          let oldIndex = items.findIndex((item) => item.id === active.id);
          let newIndex = items.findIndex((item) => item.id === over.id);
          return arrayMove(items, oldIndex, newIndex);
        });
      }
    }
  }, []);
  const handleDragEnd1 = useCallback((event) => {
    const { active, over } = event;
    if (over.id === "name") {
      return;
    }
    if (active && over && active.id !== over.id) {
      setColumnOrder((columnOrder) => {
        const oldIndex = columnOrder.indexOf(active.id);
        const newIndex = columnOrder.indexOf(over.id);
        return arrayMove(columnOrder, oldIndex, newIndex); //this is just a splice util
      });
    }
  }, []);
  const handleSideNavChanges = (type, value) => {
    switch (type) {
      case "name":
        setSideNavData({ ...sideNavData, name: value });
        break;
      case "type":
        setSideNavData({ ...sideNavData, type: value });
        break;
      case "dataSource":
        setSideNavData({ ...sideNavData, dataSource: value });
        break;
      case "dateType":
        setSideNavData({ ...sideNavData, dateType: value });
        break;
      case "start":
        setSideNavData({ ...sideNavData, date: { start: value, end: null } });
        break;
      case "date":
        setSideNavData({
          ...sideNavData,
          date: { start: value.start, end: value.end },
        });
        break;
      case "spreadsheetId":
        setSideNavData({ ...sideNavData, spreadsheetId: value });
        break;
      default:
        break;
    }
  };

  const handleFormulaCalculation = () => {
    if (actionType === "add") {
      let key = new ObjectID().toString();
      let columnDetail = {
        name: sideNavData?.name,
        date: null,
        type: sideNavData?.type,
        dataSource: sideNavData?.dataSource,
        dateType: null,
        key,
        spreadsheetId: null,
        formulaList,
      };
      let valuesClone = _.cloneDeep(reportData.values).map((value) => {
        let parsedExpression = formulaList.map((opt) => {
          if (opt.type === "operand") {
            return value[opt.value];
          } else {
            return opt.name;
          }
        });
        parsedExpression = parsedExpression.join("");
        try {
          value[key] = parse(parsedExpression)?.evaluate();
        } catch (err) {}
        return value;
      });

      setReportData({
        ...reportData,
        columns: [
          ..._.cloneDeep(reportData.columns),
          getColumnDetail({ columns: { [key]: sideNavData?.name } }, key),
        ],
        rows: rowData(valuesClone),
        values: valuesClone,
        columnDetails: [..._.cloneDeep(reportData.columnDetails), columnDetail],
      });
      setColumnOrder([...columnOrder, ...[key]]);
    } else {
      let valuesClone = _.cloneDeep(reportData.values).map((value) => {
        let parsedExpression = formulaList.map((opt) => {
          if (opt.type === "operand") {
            return value[opt.value];
          } else {
            return opt.name;
          }
        });
        parsedExpression = parsedExpression.join("");
        try {
          value[sideNavData?.key] = parse(parsedExpression)?.evaluate();
        } catch (err) {}
        return value;
      });
      setReportData({
        ...reportData,
        columns: _.cloneDeep(reportData.columns).map((column) => {
          if (column.id === sideNavData?.key) {
            return getColumnDetail(
              { columns: { [sideNavData?.key]: sideNavData?.name } },
              sideNavData?.key
            );
          } else {
            return column;
          }
        }),
        rows: rowData(valuesClone),
        values: valuesClone,
        columnDetails: [..._.cloneDeep(reportData.columnDetails), sideNavData],
      });
    }
  };

  const handleSideNavSave = () => {
    if (sideNavData?.name?.trim() === "") {
      setHasError(true);
      return;
    }
    if (sideNavData?.dataSource === COLUMN_DATA_TYPES.FORMULA) {
      handleFormulaCalculation();
    } else {
      let currentDetails =
        reportData.columnDetails.find(
          (detail) => detail.key === sideNavData?.key
        ) || {};
      let dataChanged = !_.isEqual(sideNavData, currentDetails);
      if (dataChanged) {
        dispatch(
          getCustomProfitLossData({
            entityId,
            accountingFirmId,
            start: sideNavData?.date?.start,
            end: sideNavData?.date?.end || sideNavData?.date?.start,
            loadType: "add",
            name: sideNavData?.name,
            source: sideNavData?.dataSource,
            spreadsheetId: sideNavData?.spreadsheetId,
            dateType: sideNavData?.dateType,
            key: actionType === "edit" ? sideNavData?.key : null,
          })
        );
      }
    }
    handleCloseSideNav();
  };

  return (
    <div className="p-10 mt-20 ">
      <div>
        <Drawer
          anchor={"right"}
          open={sideNavOpen}
          onClose={handleCloseSideNav}
        >
          <Box sx={{ width: "600px", paddingTop: "70px" }}>
            <Grid container className="p-10">
              <Grid item xs={12}>
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                  {actionType === "add" ? "Add" : "Edit"} {addType}
                </Typography>
              </Grid>
              <Grid item xs={12} className="mt-10">
                <Grid container>
                  <Grid item xs={8}>
                    <InputLabel className=" font-label mb-10">
                      Name:{" "}
                    </InputLabel>
                    <TextField
                      fullWidth
                      size="small"
                      value={sideNavData?.name}
                      onChange={({ target }) => {
                        handleSideNavChanges("name", target.value);
                      }}
                      error={hasError && sideNavData?.name?.trim() === ""}
                      helperText={
                        hasError && sideNavData?.name?.trim() === ""
                          ? "Name is required"
                          : ""
                      }
                    ></TextField>
                  </Grid>
                  <Grid item xs={8} className="mt-10">
                    <InputLabel className=" font-label mb-10" id="type-label">
                      Value Type:
                    </InputLabel>
                    <Select
                      fullWidth
                      size="small"
                      id="input-type"
                      value={sideNavData?.type}
                      labelId="type-label"
                      onChange={({ target }) => {
                        handleSideNavChanges("type", target.value);
                      }}
                    >
                      {Object.keys(COLUMN_TYPES)?.map((key) => {
                        return (
                          <MenuItem key={key} value={COLUMN_TYPES[key]}>
                            {COLUMN_TYPES[key]}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Grid>
                  {addType === "column" && (
                    <Grid item xs={8} className="mt-10">
                      <InputLabel className=" font-label mb-10" id="type-label">
                        Data Source:
                      </InputLabel>
                      <Select
                        fullWidth
                        size="small"
                        id="input-type"
                        value={sideNavData?.dataSource}
                        labelId="data-source-label"
                        onChange={({ target }) => {
                          handleSideNavChanges("dataSource", target.value);
                        }}
                      >
                        {Object.keys(COLUMN_DATA_TYPES)?.map((key) => {
                          return (
                            <MenuItem key={key} value={COLUMN_DATA_TYPES[key]}>
                              {COLUMN_DATA_TYPES[key]}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </Grid>
                  )}

                  {addType === "column" &&
                    sideNavData?.dataSource ===
                      COLUMN_DATA_TYPES.SPREADSHEET && (
                      <Grid item xs={8} className="mt-10">
                        <InputLabel
                          className=" font-label mb-10"
                          id="type-label"
                        >
                          Spreadsheet:
                        </InputLabel>
                        <Select
                          fullWidth
                          size="small"
                          id="input-type"
                          value={sideNavData?.spreadsheetId}
                          labelId="data-source-label"
                          onChange={({ target }) => {
                            handleSideNavChanges("spreadsheetId", target.value);
                          }}
                        >
                          {spreadsheets?.map((spreadsheet) => {
                            return (
                              <MenuItem
                                key={spreadsheet?._id}
                                value={spreadsheet?._id}
                              >
                                {spreadsheet?.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </Grid>
                    )}
                  {addType === "row" && (
                    <Grid item xs={12} className="mt-10">
                      <InputLabel
                        className=" font-label mb-10"
                        id="operand-label"
                      >
                        Row Placement:
                      </InputLabel>
                      <Grid container>
                        <Grid item xs={4}>
                          <Grid
                            container
                            className="mt-10"
                            justifyContent={"flex-start"}
                          >
                            <Typography>Before</Typography>
                            <AntSwitch
                              onChange={() =>
                                handleSideNavChanges(
                                  "rowPlacement",
                                  !sideNavData?.gstApplicable
                                )
                              }
                            />
                            <Typography>After</Typography>
                          </Grid>
                        </Grid>
                        <Grid item xs={8}>
                          <Select
                            fullWidth
                            size="small"
                            id="input-operands"
                            labelId="operand-label"
                            value={null}
                            defaultValue={null}
                          >
                            {reportData.values?.map((item) => {
                              return (
                                <MenuItem key={item.id} value={item.name}>
                                  {item.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  {(sideNavData?.dataSource === COLUMN_DATA_TYPES.FORMULA ||
                    addType === "row") && (
                    <Grid
                      item
                      xs={12}
                      style={{
                        border: "1px grey solid",
                        borderRadius: "5px",
                        padding: 10,
                      }}
                      className="mt-20"
                    >
                      <Grid container>
                        {" "}
                        <Grid item xs={12} className="mt-20">
                          <Grid container justifyContent={"space-between"}>
                            <Grid item xs={6}>
                              <Typography
                                variant="body1"
                                sx={{ fontWeight: "bold" }}
                              >
                                Formula
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={5}>
                              {" "}
                              <InputLabel
                                className=" font-label mb-10"
                                id="operand-label"
                              >
                                Operands:
                              </InputLabel>
                              <Select
                                fullWidth
                                size="small"
                                id="input-operands"
                                labelId="operand-label"
                                value={null}
                                defaultValue={null}
                                onChange={({ target }) => {
                                  setFormulaList([
                                    ...formulaList,
                                    {
                                      id: uniqueId(),
                                      value: target.value.split("_")[0],
                                      name: target.value.split("_")[1],
                                      type: "operand",
                                    },
                                  ]);
                                }}
                              >
                                {(addType === "column"
                                  ? reportData?.columns
                                  : reportData.values
                                )
                                  ?.filter((item) =>
                                    addType === "column"
                                      ? item.accessorKey !== "name"
                                      : true
                                  )
                                  ?.map((item) => {
                                    return (
                                      <MenuItem
                                        key={
                                          addType === "column"
                                            ? item.accessorKey
                                            : item.id
                                        }
                                        value={item.id + "_" + item.value}
                                      >
                                        {addType === "column"
                                          ? item.value
                                          : item.name}
                                      </MenuItem>
                                    );
                                  })}
                              </Select>
                            </Grid>
                            <Grid item xs={6}>
                              <InputLabel
                                className=" font-label mb-10"
                                id="operator-label"
                              >
                                Operators:
                              </InputLabel>
                              <Grid container spacing={2}>
                                {CALCULATION_TYPES?.map((item) => {
                                  return (
                                    <Grid item xs={3}>
                                      <Button
                                        onClick={() => {
                                          setFormulaList([
                                            ...formulaList,
                                            {
                                              id: uniqueId(),
                                              name: getFormulaSymbol(item),
                                              type: "operator",
                                            },
                                          ]);
                                        }}
                                        fullWidth
                                        variant="outlined"
                                        style={{
                                          fontSize: "16px",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {getFormulaSymbol(item)}
                                      </Button>
                                    </Grid>
                                  );
                                })}
                              </Grid>
                            </Grid>
                            <Grid item xs={1}>
                              <IconButton
                                className="mt-30"
                                onClick={() => {
                                  setAllowSortOrderFormula(true);
                                }}
                              >
                                <SwapVert />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </Grid>
                        {formulaList.length !== 0 && (
                          <Grid
                            item
                            xs={12}
                            style={{
                              border: "1px grey solid",
                              borderRadius: "5px",
                              padding: 10,
                            }}
                            className="mt-20"
                          >
                            <DndContext
                              sensors={sensors}
                              collisionDetection={closestCenter}
                              onDragEnd={handleDragEnd}
                            >
                              <SortableContext
                                disabled={!allowSortOrderFormula}
                                items={formulaList}
                                strategy={rectSortingStrategy}
                              >
                                <SortableGrid columns={4}>
                                  {formulaList.map((formula) => {
                                    return (
                                      <SortableItem
                                        key={formula?.id}
                                        id={formula?.id}
                                        name={formula?.name}
                                        disabled={!allowSortOrderFormula}
                                        onDelete={() => {
                                          let index = formulaList
                                            .map((formula) => formula.id)
                                            .indexOf(formula.id);
                                          let val = _.cloneDeep(formulaList);
                                          val.splice(index, 1);
                                          setFormulaList(val);
                                        }}
                                      />
                                    );
                                  })}
                                </SortableGrid>
                              </SortableContext>
                            </DndContext>
                          </Grid>
                        )}
                        {allowSortOrderFormula && (
                          <Grid item xs={12} className="mt-20 p-10">
                            <Grid container justifyContent={"flex-end"}>
                              <Button
                                variant="contained"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setAllowSortOrderFormula(false);
                                }}
                              >
                                Cancel
                              </Button>
                              <Button
                                variant="contained"
                                className="ml-10"
                                size="small"
                              >
                                Save
                              </Button>
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  )}
                  {sideNavData?.dataSource !== COLUMN_DATA_TYPES.FORMULA &&
                    addType === "column" && (
                      <Grid
                        item
                        xs={12}
                        style={{
                          border: "1px grey solid",
                          borderRadius: "5px",
                          padding: 10,
                        }}
                        className="mt-20"
                      >
                        <Grid container>
                          {" "}
                          <Grid item xs={12} className="mt-20">
                            <Grid container justifyContent={"space-between"}>
                              <Grid item xs={6}>
                                <Typography
                                  variant="body1"
                                  sx={{ fontWeight: "bold" }}
                                >
                                  Date Range
                                </Typography>
                                <Select
                                  className="mt-10"
                                  fullWidth
                                  size="small"
                                  value={sideNavData?.dateType}
                                  labelId="date-type-label"
                                  onChange={({ target }) => {
                                    handleSideNavChanges(
                                      "dateType",
                                      target.value
                                    );
                                  }}
                                >
                                  {Object.keys(DATE_RANGE_TYPES)?.map((key) => {
                                    return (
                                      <MenuItem
                                        key={key}
                                        value={DATE_RANGE_TYPES[key]}
                                      >
                                        {DATE_RANGE_TYPES[key]}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </Grid>
                            </Grid>
                          </Grid>
                          {sideNavData?.dateType === DATE_RANGE_TYPES.MONTH && (
                            <Grid item xs={6} className="mt-20">
                              <DatePicker
                                format={"MMM YYYY"}
                                style={{ width: "100%" }}
                                allowClear={false}
                                size="large"
                                value={dayjs(sideNavData?.date?.start)}
                                picker="month"
                                onChange={(date, dateString) => {
                                  handleSideNavChanges("start", dateString);
                                }}
                                getPopupContainer={(triggerNode) => {
                                  return triggerNode.parentNode;
                                }}
                              />
                            </Grid>
                          )}
                          {sideNavData?.dateType ===
                            DATE_RANGE_TYPES.CUSTOM && (
                            <Grid item xs={6} className="mt-20">
                              <RangePicker
                                size="large"
                                format={"MMM YY"}
                                picker="month"
                                value={[
                                  dayjs(sideNavData?.date?.start),
                                  dayjs(sideNavData?.date?.end),
                                ]}
                                onChange={(val) => {
                                  handleSideNavChanges("date", {
                                    start: val[0].format("YYYY-MM"),
                                    end: val[1].format("YYYY-MM"),
                                  });
                                }}
                                getPopupContainer={(triggerNode) => {
                                  return triggerNode.parentNode;
                                }}
                              ></RangePicker>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className="mt-20 p-10">
              <Grid container justifyContent={"flex-end"}>
                <Button
                  variant="contained"
                  color="inherit"
                  onClick={handleCloseSideNav}
                >
                  Cancel
                </Button>
                <Button
                  disabled={allowSortOrderFormula}
                  variant="contained"
                  className="ml-10"
                  onClick={() => {
                    handleSideNavSave();
                  }}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Drawer>
        <Grid container className="full-container" style={{ height: "90vh" }}>
          <Grid item xs={6}>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Custom report
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Grid container justifyContent={"right"}>
              <Grid item>
                <IconButton
                  color={allowColumnReorder ? "primary" : "default"}
                  onClick={() => {
                    setAllowColumnReorder(!allowColumnReorder);
                  }}
                >
                  <SwapHoriz />
                </IconButton>
              </Grid>
              <Grid item>
                <Grid container justifyContent={"right"}>
                  <IconButton id="button-clientEntityView" color="inherit">
                    <SettingsOutlined
                      style={{
                        color: "grey",
                        fontSize: 20,
                      }}
                      onClick={(event) => handleMenuItemClick(event)}
                    />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuItemClose}
                  >
                    {/* <MenuItem
                      onClick={() => {
                        setAddType("row");
                        setSideNavOpen(true);
                        handleMenuItemClose();
                      }}
                    >
                      <Typography className="menuItemTypography">
                        Add row
                      </Typography>
                    </MenuItem> */}
                    <MenuItem
                      onClick={() => {
                        setAddType("column");
                        setSideNavOpen(true);
                        handleMenuItemClose();
                      }}
                    >
                      <Typography className="menuItemTypography">
                        Add column
                      </Typography>
                    </MenuItem>
                  </Menu>
                </Grid>
              </Grid>
              {/* <Grid item>
                <Button variant="contained">Save</Button>
              </Grid> */}
            </Grid>
          </Grid>

          {!isFetching && reportData?.rows?.length !== 0 && reportData.rows && (
            <DndContext
              collisionDetection={closestCenter}
              modifiers={[restrictToHorizontalAxis]}
              onDragEnd={handleDragEnd1}
              sensors={sensors1}
            >
              <div className="p-2">
                <table
                  style={{
                    borderSpacing: "0px",
                    display: "inline-block",
                    overflow: "auto",
                    height: "80vh",
                    width: "90vw",
                  }}
                >
                  <thead>
                    {table.getHeaderGroups().map((headerGroup, index) => (
                      <tr className="tr-sticky" key={headerGroup.id}>
                        <SortableContext
                          items={columnOrder}
                          strategy={horizontalListSortingStrategy}
                        >
                          {headerGroup.headers.map((header) => (
                            <DraggableTableHeader
                              key={header.id}
                              header={header}
                              columns={reportData.columns}
                              className={
                                header.id === "name" ? "th-sticky" : ""
                              }
                              allowColumnReorder={allowColumnReorder}
                              handleEditColumn={handleEditColumn}
                            />
                          ))}
                        </SortableContext>
                      </tr>
                    ))}
                  </thead>
                  <tbody>
                    {table.getRowModel().rows.map((row, index) => (
                      <tr key={row.id}>
                        {row.getVisibleCells().map((cell) => (
                          <SortableContext
                            key={cell.id}
                            items={columnOrder}
                            strategy={horizontalListSortingStrategy}
                          >
                            <DragAlongCell key={cell.id} cell={cell} />
                          </SortableContext>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </DndContext>
          )}
        </Grid>
      </div>

      {isFetching && <Loading wait />}
    </div>
  );
};

export default CustomReportPlayGround;
