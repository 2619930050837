import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { handleRequest } from "../../helper/utils";

export const getProfitLossData = createAsyncThunk(
  "profitAndLoss/getProfitLossData",
  async (
    { entityId, accountingFirmId, spreadSheetId, reportingMonth },
    thunkAPI
  ) => {
    const endpoint = `/accountingFirm/${accountingFirmId}/profit-and-loss/${entityId}/pldata/${spreadSheetId}?reportingMonth=${reportingMonth}`;
    return handleRequest(
      endpoint,
      {},
      "getProfitLossData",
      thunkAPI,
      null,
      "GET",
      true
    );
  }
);

export const getCustomProfitLossData = createAsyncThunk(
  "profitAndLoss/getCustomProfitLossData",
  async (
    {
      entityId,
      accountingFirmId,
      start,
      end,
      loadType,
      name = "",
      source,
      spreadsheetId = null,
      dateType,
      key = null,
    },
    thunkAPI
  ) => {
    const endpoint = `/accountingFirm/${accountingFirmId}/profit-and-loss/${entityId}/custom?start=${start}&end=${end}&loadType=${loadType}&name=${name}&source=${source}&dateType=${dateType}&spreadsheetId=${spreadsheetId}&key=${key}`;
    return handleRequest(
      endpoint,
      {},
      "getCustomProfitLossData",
      thunkAPI,
      null,
      "GET",
      true
    );
  }
);

export const sendFileInEmail = createAsyncThunk(
  "prifleAndLoss/sendFileInEmail",
  async (
    { entityId, accountingFirmId, selectedSpreadSheet, payload },
    thunkAPI
  ) => {
    const endPoint = `/accountingFirm/${accountingFirmId}/profit-and-loss/${entityId}/${selectedSpreadSheet}/sendFileInEmail`;
    return handleRequest(
      endPoint,
      payload,
      "sendFileInEmail",
      thunkAPI,
      null,
      "POST"
    );
  }
);

export const downloadExcelFile = createAsyncThunk(
  "prifleAndLoss/sendFileInEmail",
  async (
    { entityId, accountingFirmId, selectedSpreadSheet, plData },
    thunkAPI
  ) => {
    const endPoint = `/accountingFirm/${accountingFirmId}/profit-and-loss/${entityId}/${selectedSpreadSheet}/downloadExcelFile`;
    return handleRequest(
      endPoint,
      plData,
      "sendFileInEmail",
      thunkAPI,
      null,
      "POST"
    );
  }
);

const initialState = {
  data: [],
  plData: { rows: [], columns: [] },
  customPlData: null,
  excelFile: null,
  isFetching: false,
  isSuccess: false,
  isDownloadSuccess: false,
  isDownloadFetching: false,
  isError: false,
  errorMessage: "",
};
export const profitAndLossSlice = createSlice({
  name: "profitAndLoss",
  initialState,
  reducers: {
    resetData: (state) => {
      state.plData = { rows: [], columns: [] };
      return state;
    },
    resetDownloadSuccess: (state) => {
      state.isDownloadSuccess = false;
      return state;
    },
  },
  extraReducers: {
    [getProfitLossData.fulfilled]: (state, { payload }) => {
      state.data = payload.data;
      state.plData = payload.plData;
      localStorage.setItem(
        "reportList",
        JSON.stringify(payload.plData?.reportList)
      );
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [getProfitLossData.rejected]: (state, { payload }) => {
      state.isError = true;
      state.isFetching = false;
      state.isSuccess = false;
      state.errorMessage = payload;
      return state;
    },
    [getProfitLossData.pending]: (state) => {
      state.isFetching = true;
      state.isSuccess = false;
      return state;
    },
    [getCustomProfitLossData.fulfilled]: (state, { payload }) => {
      state.data = payload.data;
      state.customPlData = payload.plData;
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [getCustomProfitLossData.rejected]: (state, { payload }) => {
      state.isError = true;
      state.isFetching = false;
      state.isSuccess = false;
      state.errorMessage = payload;
      return state;
    },
    [getCustomProfitLossData.pending]: (state) => {
      state.isFetching = true;
      state.isSuccess = false;
      return state;
    },
    [sendFileInEmail.fulfilled]: (state, { payload }) => {
      state.data = payload.data;
      state.isDownloadFetching = false;
      state.isDownloadSuccess = true;
      return state;
    },
    [sendFileInEmail.rejected]: (state, { payload }) => {
      state.isError = true;
      state.isDownloadFetching = false;
      state.isDownloadSuccess = false;
      state.errorMessage = payload;
      return state;
    },
    [sendFileInEmail.pending]: (state) => {
      state.isDownloadFetching = true;
      state.isDownloadSuccess = false;
      return state;
    },
    [downloadExcelFile.fulfilled]: (state, { payload }) => {
      state.excelFile = payload.data;
      state.isDownloadFetching = false;
      state.isDownloadSuccess = true;
      return state;
    },
    [downloadExcelFile.rejected]: (state, { payload }) => {
      state.isError = true;
      state.isDownloadFetching = false;
      state.isDownloadSuccess = false;
      state.errorMessage = payload;
      return state;
    },
    [downloadExcelFile.pending]: (state) => {
      state.isDownloadFetching = true;
      state.isDownloadSuccess = false;
      return state;
    },
  },
});
export const { resetData, resetDownloadSuccess } = profitAndLossSlice.actions;

export default profitAndLossSlice.reducer;
