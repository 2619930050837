import {
  Grid,
  IconButton,
  InputLabel,
  Typography,
  TextField,
  Button,
  Avatar,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useRef, useState } from "react";
import { ColorPalette } from "../../../constants/colorPalette";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { Formik } from "formik";
import ConfirmModal from "../../../components/CustomModal/ConfirmModal";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { accFirmProfile } from "../../../redux/User/UserSlice";
import { checkDataAvailibility } from "../../../helper/utils";
import Loading from "../../../components/LoadingIndicator/Loading";
import { TabContext, TabPanel } from "@mui/lab";
import { TabContainer } from "../../../components/TabContainer/TabContainer";

function FirmProfile() {
  const fileInputRef = useRef();
  const dispatch = useDispatch();
  const { accountingFirmId } = useParams();
  const users = useSelector((state) => state.user);
  const [formValues, setFormValues] = useState(null);
  const [value, setValue] = useState("1");
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(users.isFetching);
  const fileSize = 2097152;

  const stateData = checkDataAvailibility(users);

  const tabs = [
    { label: "Accounting Firm Details", value: "1" },
    { label: "Settings", value: "2" },
  ];

  useEffect(() => {
    setIsLoading(users.isFetching);
  }, [users.isFetching]);

  const handleIconClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
      return;
    }
  };

  const handleSubmit = () => {
    const formData = new FormData();
    if (formValues) {
      formData.append("accFirmImg", formValues.file);
      formData.append("firmName", formValues.firmName);
      dispatch(accFirmProfile({ formData, accountingFirmId }));
      setConfirmModalOpen(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div style={{ display: "flex", width: "100%" }}>
      {confirmModalOpen && (
        <ConfirmModal
          message={"Are you sure ypu want to save the changed inputs?"}
          confirmFunction={handleSubmit}
          declineFunction={() => setConfirmModalOpen(false)}
          modalOpen={confirmModalOpen}
          setModalOpen={setConfirmModalOpen}
        />
      )}
      <Formik
        initialValues={{
          firmName: stateData.accFirmName,
          adminEmail: "",
          file: stateData.accFirmImg || null,
        }}
        validate={(values) => {
          const errors = {};

          // File validation
          if (values.file) {
            if (values.file.size >= fileSize) {
              errors.file = "Please upload an image smaller than 2MB";
            } else if (
              values.file.type !== "image/svg+xml" &&
              values.file.type !== "image/svg"
            ) {
              errors.file = "Please upload an SVG image";
            }
          }

          if (!values.firmName) {
            errors.firmName = "Please enter firm name";
          } else if (
            values.firmName.length !== 0 &&
            /[!@#$%^&*]/g.test(values.firmName)
          ) {
            errors.firmName = "Special characters not allowed";
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          setFormValues(values);
          setConfirmModalOpen(true);
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          setFieldValue,
          dirty,
          isValid,
          resetForm,
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid container p={4}>
              <Typography variant="h4" fontWeight="bold">
                Accounting Firm Details
              </Typography>
            </Grid>
            {isLoading ? (
              <Loading />
            ) : (
              <>
                <TabContext value={value}>
                  <TabContainer
                    tabs={tabs}
                    value={value}
                    onChange={handleTabChange}
                  />
                  <Grid container>
                    <TabPanel value="1">
                      <Grid item>
                        <InputLabel className="mb-20">
                          Acounting Firm Image
                        </InputLabel>
                        {(values.file && !errors.file) ||
                        stateData.accFirmImg ? (
                          <img
                            src={
                              values.file
                                ? URL.createObjectURL(values.file)
                                : stateData.accFirmImg
                            }
                            alt=""
                            width={80}
                            height={80}
                            style={{ borderRadius: "50%" }}
                          />
                        ) : (
                          <Avatar
                            src="/broken-image.jpg"
                            alt="Profile Image"
                            sx={{ height: 88, width: 88 }}
                          />
                        )}
                        <Grid
                          item
                          sx={{ position: "relative", bottom: 94, left: 61 }}
                        >
                          <input
                            type="file"
                            ref={fileInputRef}
                            style={{ display: "none" }}
                            onChange={(e) =>
                              setFieldValue("file", e.currentTarget.files[0])
                            }
                          />
                          {values.file && !errors.file ? (
                            <IconButton
                              onClick={() => {
                                setFieldValue("file", null);
                                fileInputRef.current.value = null;
                              }}
                            >
                              <CloseIcon />
                            </IconButton>
                          ) : (
                            <div onClick={() => handleIconClick(setFieldValue)}>
                              <CameraAltIcon
                                sx={{
                                  cursor: "pointer",
                                  "&:hover": {
                                    cursor: "pointer",
                                  },
                                }}
                              />
                            </div>
                          )}
                        </Grid>
                        {errors.file && (
                          <Typography sx={{ color: ColorPalette.danger }}>
                            {errors.file}
                          </Typography>
                        )}
                      </Grid>

                      <Grid>
                        <div>
                          <InputLabel className="mb-10">
                            Accounting Firm Name
                          </InputLabel>
                          <TextField
                            name="firmName"
                            size="small"
                            value={values.firmName}
                            error={!!errors.firmName && !!touched.firmName}
                            helperText={touched.firmName && errors.firmName}
                            onChange={handleChange}
                          />
                        </div>
                        {/* <div
                          style={{ flexDirection: "column", marginTop: "30px" }}
                        >
                          <InputLabel className="mb-10">
                            {" "}
                            Admin Email
                          </InputLabel>
                          <TextField
                            name="adminEmail"
                            size="small"
                            inputProps={{ readOnly: true }}
                            value={values.adminEmail}
                            error={!!errors.adminEmail && !!touched.adminEmail}
                            helperText={touched.adminEmail && errors.adminEmail}
                            onChange={handleChange}
                          />
                        </div> */}
                      </Grid>
                    </TabPanel>
                    <TabPanel value="2">
                      <h3>This is tab 2</h3>
                    </TabPanel>
                  </Grid>
                </TabContext>

                <Grid
                  container
                  display="flex"
                  justifyContent="right"
                  alignItems="center"
                  gap={3}
                  mt={3}
                >
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={!(dirty && isValid)}
                  >
                    Save
                  </Button>
                  <Button
                    variant="contained"
                    color="inherit"
                    onClick={() => resetForm()}
                    disabled={!dirty}
                  >
                    Reset
                  </Button>
                </Grid>
              </>
            )}
          </form>
        )}
      </Formik>
    </div>
  );
}

export default FirmProfile;
