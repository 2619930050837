import { useEffect, useState } from "react";
import {
  Grid,
  Button,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  IconButton,
  Typography,
  Divider,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useDispatch, useSelector } from "react-redux";
import {
  addEntity,
  updateEntity,
  deleteEntity,
  getUsersJoinedByInvitation,
} from "../../../redux/ClientSpace/ClientSpaceSlice";
import "./ClientSpace.css";
import { useNavigate, useParams } from "react-router-dom";
import { getSingleClientSpace } from "../../../redux/ClientSpace/ClientSpaceSlice";
import CustomModal from "../../../components/CustomModal/CustomModal";
import SpaceEntities from "./SpaceEntities";
import { Formik } from "formik";
import { ColorPalette } from "../../../constants/colorPalette";
import CloseIcon from "@mui/icons-material/Close";
import { toTitleCase } from "../../../helper/titleCaseConverter";

export default function ClientEntities() {
  const dispatch = useDispatch();

  const { clientSpaceId, accountingFirmId } = useParams();
  const [currentEntityId, setCurrentEntityId] = useState();
  const [entityRows, setEntityRows] = useState([]);
  const [editValues, setEditValues] = useState();
  const [addEntityModalOpen, setAddEntityModalOpen] = useState(false);
  const [type, setType] = useState("add");
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getSingleClientSpace({ id: clientSpaceId, accountingFirmId }));
    dispatch(getUsersJoinedByInvitation({ accountingFirmId }));
  }, [clientSpaceId, accountingFirmId, dispatch]);

  const { isFetching } = useSelector(({ clientSpaces }) => clientSpaces);

  const currentClientSpace = useSelector(
    (state) => state.clientSpaces.currentClientSpace
  );
  const entities = useSelector((state) => state.clientSpaces.entities);

  const createEntity = (data) => {
    if (type.toLowerCase() === "add") {
      dispatch(
        addEntity({ payload: { ...data, clientSpaceId }, accountingFirmId })
      );
    } else if (type.toLowerCase() === "edit") {
      dispatch(
        updateEntity({
          payload: { ...data, id: currentEntityId },
          accountingFirmId,
        })
      );
    }
    setAddEntityModalOpen(false);
  };

  const createEntityRows = (entities) => {
    let list = [];
    entities?.forEach((entity) => {
      list.push({
        id: entity._id,
        name: entity.entityName,
        type: entity.entityType,
        isOnboarded: entity.isOnboarded ?? false,
        xeroRefresh: entity.xeroLastRefresh || false,
      });
    });
    return list;
  };

  useEffect(() => {
    setEntityRows(createEntityRows(entities));
  }, [entities]);

  const entityTypes = ["COMPANY", "TRUST", "SOLE TRADER", "PARTNERSHIP"];

  const handleEntityDelete = (id) => {
    setEntityRows(entityRows.filter((entity) => entity.id !== id));
    dispatch(deleteEntity({ id, accountingFirmId }));
  };

  return (
    <div style={{ paddingRight: "2%", paddingLeft: "2%" }}>
      <Grid container marginY={3}>
        <Grid
          onClick={() =>
            navigate(`../accountingFirm/${accountingFirmId}/clientSpaces`)
          }
          item
        >
          <IconButton
            id="buutton-backToFirmPortal"
            style={{ borderRadius: 0 }}
            size="small"
          >
            <ArrowBackIcon color={ColorPalette.primary} />
            <label style={{ marginLeft: 7 }}>Back to firm portal</label>
          </IconButton>
        </Grid>
      </Grid>
      <Grid container sx={{ typography: "body1" }}>
        <Grid item xs={12}>
          <SpaceEntities
            isFetching={isFetching}
            title={"Entity List"}
            subtitle={currentClientSpace.clientGroupName}
            data={entityRows}
            setModalOpen={setAddEntityModalOpen}
            deleteFunction={handleEntityDelete}
            setEditValues={setEditValues}
            setType={setType}
            setCurrentEntityId={setCurrentEntityId}
          />
        </Grid>

        {/* Add Entity modal */}
        <Grid item>
          <CustomModal
            modalOpen={addEntityModalOpen}
            setModalOpen={setAddEntityModalOpen}
            overflow="hidden"
            minWidth="70vh"
            maxWidth="md"
          >
            <div>
              <Grid container flexDirection="column">
                <Grid
                  container
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  mb={2}
                >
                  <Typography fontSize={18} fontWeight="bold">
                    Add Entity
                  </Typography>
                  <IconButton
                    id="button-addGroupCloseIcon"
                    style={{ borderRadius: 0 }}
                    size="small"
                    onClick={() => {
                      setAddEntityModalOpen(false);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
                <Divider sx={{ width: "100%", color: ColorPalette.danger }} />
                <Grid container p={2}>
                  <Grid item xs={12}>
                    <Formik
                      initialValues={{
                        entityName: type === "edit" ? editValues.name : "",
                        entityType: type === "edit" ? editValues.type : "",
                      }}
                      validate={(values) => {
                        const errors = {};

                        if (!values.entityName) {
                          errors.isEntityNameEmpty = true;
                          errors.entityName = "Please enter entity name";
                        }
                        if (!values.entityType) {
                          errors.isEntityTypeEmpty = true;
                          errors.entityType = "Please enter entity type";
                        }
                        if (
                          entityRows.some(
                            (row) =>
                              row.name.toLowerCase() ===
                              values.entityName.toLowerCase().trim()
                          )
                        ) {
                          errors.entityName =
                            "This entity name has already been used. Please use a different name.";
                        }
                        return errors;
                      }}
                      onSubmit={(data, { setSubmitting }) => {
                        createEntity(data, setSubmitting);
                      }}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleSubmit,
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <Grid item xs={12} marginTop={2} marginBottom={4}>
                            <InputLabel
                              id="entity-name"
                              sx={{
                                color:
                                  !!errors.entityName && !!touched.entityName
                                    ? ColorPalette.black
                                    : "",
                              }}
                              className="mb-10"
                            >
                              Entity Name
                            </InputLabel>
                            <TextField
                              id="input-entityName"
                              fullWidth
                              name="entityName"
                              error={
                                !!errors.entityName && !!touched.entityName
                              }
                              value={values.entityName}
                              onChange={handleChange}
                              size="small"
                            />
                            <Typography
                              id="error-groupRequiredField"
                              variant="caption"
                              color={ColorPalette.danger}
                              pl={1}
                            >
                              {!!errors.entityName &&
                                !!touched.entityName &&
                                errors.entityName}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <InputLabel
                              id="entity-type"
                              sx={{
                                color:
                                  !!errors.entityType && !!touched.entityType
                                    ? ColorPalette.black
                                    : "",
                              }}
                              className="mb-10"
                            >
                              Entity Type
                            </InputLabel>
                            <Select
                              id="input-entityType"
                              labelId="entity-type"
                              size="small"
                              value={values.entityType}
                              name="entityType"
                              fullWidth
                              error={
                                !!errors.entityType && !!touched.entityType
                              }
                              onChange={handleChange}
                            >
                              {entityTypes.map((type, index) => (
                                <MenuItem key={index} value={type}>
                                  {toTitleCase(type)}
                                </MenuItem>
                              ))}
                            </Select>
                            {!!errors.entityType && !!touched.entityType && (
                              <div
                                id="error-requiredField"
                                style={{
                                  fontSize: 12,
                                  color: ColorPalette.danger,
                                  marginLeft: "15px",
                                  paddingTop: "3px",
                                }}
                              >
                                {errors.entityType}
                              </div>
                            )}
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "right",
                              gap: "10px",
                              marginTop: "40px",
                            }}
                          >
                            <Button
                              id="button-cancelEntity"
                              variant="outlined"
                              onClick={() => setAddEntityModalOpen(false)}
                            >
                              Cancel
                            </Button>
                            <Button
                              id="button-addEntity"
                              variant="contained"
                              onClick={handleSubmit}
                              sx={{ minWidth: "80px", paddingX: "15px" }}
                              type="submit"
                            >
                              Add
                            </Button>
                          </Grid>
                        </form>
                      )}
                    </Formik>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </CustomModal>
        </Grid>
      </Grid>
    </div>
  );
}
