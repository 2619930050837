import React from "react";
import { Grid, Box, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { ColorPalette } from "../../constants/colorPalette";

export default function ClientSpaceCard({
  marginTop,
  title,
  subtitle,
  setModalOpen,
  setType,
  addTitle,
  children,
  buttonId,
  titleId,
}) {
  return (
    // <Grid container>
    <Grid
      item
      borderRadius={4}
      xs={12}
      width={"95vw"}
      marginTop={marginTop || 0}
      className="container"
      paddingTop={5}
      paddingBottom={4}
      overflow="auto"
    >
      <Grid container>
        <Grid item xs={12}>
          <Grid container ml={1} mb={1} justifyContent={"space-between"}>
            <div>
              <label
                id={`text-${titleId}`}
                style={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  paddingLeft: 8,
                }}
              >
                {title}
              </label>
              {subtitle ? (
                <label
                  style={{
                    fontSize: "16px",
                    fontWeight: "lighter",
                    color: ColorPalette.primary,
                    paddingLeft: 8,
                  }}
                >
                  ({subtitle})
                </label>
              ) : null}
            </div>
            {addTitle && (
              <Box pr={4}>
                <Button
                  id={`button-${buttonId}`}
                  onClick={() => {
                    setModalOpen(true);
                    setType("add");
                  }}
                  variant="contained"
                  startIcon={<AddIcon />}
                >
                  {addTitle}
                </Button>
              </Box>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} marginTop={2}>
          {children}
        </Grid>
      </Grid>
    </Grid>
    // </Grid>
  );
}
