import { TabList } from "@mui/lab";
import { Tab, tabsClasses } from "@mui/material";
import { ColorPalette } from "../../constants/colorPalette";

const tabStyle = {
  color: "#333",
  border: "1px solid #ccc",
  borderRadius: "6px",
  minHeight: "37px",
  height: "37px",
  textTransform: "none",
  boxShadow: "0px 2px 4px rgba(16, 24, 40, 0.1)",
};

const selectedTabStyle = {
  ...tabStyle,
  backgroundColor: ColorPalette.primary,
  color: "#fff",
  boxShadow:
    "0px 4px 6px rgba(16, 24, 40, 0.15), 0px 2px 4px rgba(16, 24, 40, 0.1)",
};

export const TabContainer = ({ tabs, value, onChange, disabled }) => {
  return (
    <TabList
      onChange={onChange}
      value={value}
      TabIndicatorProps={{ style: { display: "none" } }}
      sx={{
        maxWidth: "90%",
        overflow: "auto",
        "& .MuiTab-root": {
          marginRight: "10px",
          width: "200px",
        },
        [`& .${tabsClasses.scrollButtons}`]: {
          "&.Mui-disabled": { opacity: 0.3 },
          marginTop: "-10px",
        },
      }}
      variant="scrollable"
      scrollButtons="auto"
    >
      {tabs.map((tab) => (
        <Tab
          key={tab.value}
          label={tab.label}
          value={tab.value}
          style={value === tab.value ? selectedTabStyle : tabStyle}
          disabled={disabled}
        />
      ))}
    </TabList>
  );
};
