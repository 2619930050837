import { useEffect, useState } from "react";

import {
  Grid,
  Button,
  TextField,
  FormControl,
  Stack,
  IconButton,
  Typography,
  Divider,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { DeleteOutline } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import {
  getInvitations,
  inviteUser,
  deleteInvitation,
  resendInvitation,
} from "../../../redux/User/UserSlice";
import "./Team.css";
import { Close, Done } from "@mui/icons-material";
import Chip from "@mui/material/Chip";
import TableListCard from "../../../components/TableListCard/TableListCard";
import ConfirmModal from "../../../components/CustomModal/ConfirmModal";
import Loading from "../../../components/LoadingIndicator/Loading";
import CustomModal from "../../../components/CustomModal/CustomModal";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";
import { ColorPalette } from "../../../constants/colorPalette";
import moment from "moment";
import { toTitleCase } from "../../../helper/titleCaseConverter";
import { useParams } from "react-router-dom";

export default function Team() {
  const dispatch = useDispatch();
  const { accountingFirmId } = useParams();
  const [, setType] = useState("add");
  const [inviteUsersField, setInviteUsersField] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [deleteId, setDeleteId] = useState();
  const [inviteModalOpen, setInviteModalOpen] = useState(false);
  let invitedUsers = useSelector((state) => state.user.invitedUsers);
  const { isSuccess, isFetching } = useSelector(({ user }) => user);
  const [initialLoadingState, setInitialLoadingState] = useState(false);

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [inviteConfirmModal, setInviteConfirmModal] = useState(false);
  const [resendConfirmModal, setResendConfirmModal] = useState(false);

  useEffect(() => {
    dispatch(getInvitations({ accountingFirmId }));
  }, [dispatch, accountingFirmId]);
  const initialState = {
    isEmailEmpty: false,
    email: "",
  };
  const [errors, setErrors] = useState(initialState);

  useEffect(() => {
    if (!isFetching && !initialLoadingState) {
      setInitialLoadingState(true);
    }
  }, [isFetching, initialLoadingState]);

  // useEffect(() => {
  //   function createData() {
  //     let list = [];
  //     invitedUsers?.forEach((item) => {
  //       list.push({
  //         id: item._id,
  //         email: item.email,
  //         status: item.status,
  //         hasJoined: item.hasJoined,
  //         createdAt: item.createdAt,
  //       });
  //     });
  //     return list;
  //   }
  //   setRows(createData);
  // }, [invitedUsers]);

  // useEffect(() => {
  //   dispatch(resetFlags());
  //   //eslint-disable-next-line
  // }, [isError, isSuccess, successMessage, errorMessage]);

  useEffect(() => {
    if (isSuccess) {
      setInviteModalOpen(false);
    }
  }, [isSuccess]);

  const sendInvitation = () => {
    const payload = selectedUsers?.map((item) => item);
    dispatch(inviteUser({ payload, accountingFirmId }));
    setSelectedUsers([]);
    setInviteConfirmModal(false);
  };
  const [updateId, setUpdateId] = useState();

  const resendInvitationFunc = () => {
    const id = updateId;
    dispatch(resendInvitation({ id, accountingFirmId }));
    setResendConfirmModal(false);
  };

  const deleteCurrentInvitation = (id) => {
    dispatch(deleteInvitation({ id, accountingFirmId }));
    // setRows(rows.filter((item) => item.id.toString() !== id.toString()));
  };

  const confirmDeleteFunction = () => {
    deleteCurrentInvitation(deleteId);
    setDeleteModalOpen(false);
  };

  const handleChipDelete = (data) => {
    setSelectedUsers(selectedUsers?.filter((item) => item !== data));
  };

  const handleInviteUsersChange = (event) => {
    setInviteUsersField(event.target.value);
    if (event.target.value?.includes(",")) {
      setSelectedUsers(selectedUsers?.concat(inviteUsersField.split(",")));
      setInviteUsersField("");
    }
    if (!event.target.value) {
      setErrors(initialState);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      if (!inviteUsersField) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          isEmailEmpty: true,
          email: "Required Field",
        }));
      } else if (!emailRegex.test(inviteUsersField)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: "Invalid email address",
        }));
      } else if (selectedUsers?.includes(inviteUsersField)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: "Can't enter same email",
        }));
      } else {
        setErrors(initialState);
        setSelectedUsers(selectedUsers?.concat(inviteUsersField));
        setInviteUsersField("");
      }
    }
  };

  const cancelInviteMembers = () => {
    setInviteModalOpen(false);
    setSelectedUsers([]);
    setInviteUsersField("");
    setErrors(initialState);
  };

  return (
    <div style={{ margin: "2%" }}>
      <Grid container>
        {inviteConfirmModal && (
          <ConfirmModal
            modalOpen={inviteConfirmModal}
            setModalOpen={setInviteConfirmModal}
            onClose={() => setInviteConfirmModal(false)}
            confirmFunction={sendInvitation}
            declineFunction={() => setInviteConfirmModal(false)}
            message="Are you sure you want to send the invite?"
          />
        )}
        {deleteModalOpen && (
          <ConfirmModal
            modalOpen={deleteModalOpen}
            setModalOpen={setDeleteModalOpen}
            onClose={() => setDeleteModalOpen(false)}
            confirmFunction={confirmDeleteFunction}
            declineFunction={() => setDeleteModalOpen(false)}
            message="Are you sure you want to delete?"
          />
        )}
        {resendConfirmModal && (
          <ConfirmModal
            modalOpen={resendConfirmModal}
            setModalOpen={setResendConfirmModal}
            onClose={() => setResendConfirmModal(false)}
            confirmFunction={resendInvitationFunc}
            declineFunction={() => setResendConfirmModal(false)}
            message="Are you sure you want to resend invitation?"
          />
        )}
        <CustomModal
          onClose={cancelInviteMembers}
          modalOpen={inviteModalOpen}
          setModalOpen={setInviteModalOpen}
          overflow="hidden"
          minWidth="50vw"
          maxWidth="md"
        >
          <div>
            {isFetching && (
              <Grid>
                <Loading wait={true} />
              </Grid>
            )}

            <Grid container padding={2}>
              <Grid item xs={12}>
                <Grid container mb={2}>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginBottom: "15px",
                    }}
                  >
                    <Typography fontSize={18} fontWeight="bold">
                      Invite Team Members
                    </Typography>
                    <IconButton
                      id="button-addGroupCloseIcon"
                      style={{ borderRadius: 0 }}
                      size="small"
                      onClick={cancelInviteMembers}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                  <Divider sx={{ width: "100%", color: ColorPalette.danger }} />
                </Grid>
                <Grid item xs={12} marginTop={2} px={2} pt={2}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Stack direction="row">
                        <div>
                          {selectedUsers?.map((data, key) => {
                            return (
                              <Chip
                                key={key}
                                label={data}
                                variant="outlined"
                                onDelete={() => {
                                  handleChipDelete(data);
                                }}
                                style={{ margin: 2 }}
                              />
                            );
                          })}
                        </div>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} marginTop={2}>
                      <FormControl fullWidth>
                        <TextField
                          id="input-inviteUsersWithEmail"
                          variant="outlined"
                          fullWidth
                          label="Invite Users (Enter an email and press enter to add more)"
                          value={inviteUsersField}
                          onChange={handleInviteUsersChange}
                          onKeyDown={handleKeyDown}
                          disabled={isFetching}
                          error={errors.isEmailEmpty || errors.email !== ""}
                          helperText={
                            errors.isEmailEmpty
                              ? "Required Field"
                              : errors.email
                          }
                        />
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: "flex",
                        justifyContent: "right",
                        gap: "10px",
                        marginTop: "30px",
                      }}
                    >
                      <Button
                        variant="outlined"
                        fullwidth
                        id="button-inviteMembers"
                        onClick={cancelInviteMembers}
                      >
                        <Typography className="text-normal">Cancel</Typography>
                      </Button>
                      <Button
                        id="button-invite"
                        variant="contained"
                        onClick={() => setInviteConfirmModal(true)}
                        disabled={!selectedUsers?.length > 0 || isFetching}
                      >
                        <Typography className="text-normal">Invite</Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </CustomModal>

        <Grid container direction="column">
          <Typography
            id="text-welcomeBack"
            variant="h1"
            sx={{
              fontSize: { xs: 24, sm: 36 },
              fontWeight: "bold",
              color: ColorPalette.secondaryDark,
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
          >
            Invite
          </Typography>
        </Grid>
        {isFetching || !initialLoadingState ? (
          <Loading />
        ) : (
          <div style={{ width: "100%" }}>
            <TableListCard
              title={" Invited Members List"}
              addTitle={"Invite Members"}
              modalOpen={inviteModalOpen}
              setModalOpen={setInviteModalOpen}
              setType={setType}
              titleId={"inviteMembersList"}
              buttonId={"inviteMembers"}
            >
              {invitedUsers.length === 0 ? (
                <Grid
                  container
                  justifyContent={"center"}
                  sx={{ paddingTop: "30px" }}
                >
                  <Grid item xs={6}>
                    <Grid container justifyContent={"center"}>
                      <Typography variant="h6">No Invitations Found</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <Grid container>
                  <Grid item xs={12} marginTop={2}>
                    <div>
                      <TableContainer
                        style={{
                          minWidth: "900px",
                          maxHeight: "65vh",
                          overflow: "auto",
                        }}
                      >
                        <Table>
                          <TableHead
                            style={{
                              position: "sticky",
                              top: "0",
                              zIndex: 1,
                              backgroundColor: ColorPalette.white,
                            }}
                          >
                            <TableRow>
                              {[
                                "Email",
                                "Joined",
                                "Status",
                                "Created At",
                                "Actions",
                              ].map((headers, index) => (
                                <TableCell className="table-header" key={index}>
                                  {headers}
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {invitedUsers?.map((row, index) => (
                              <TableRow
                                key={row.email + index}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  width={"30%"}
                                  component="th"
                                  scope="row"
                                >
                                  {row?.email}
                                </TableCell>
                                <TableCell>
                                  {row?.hasJoined ? (
                                    <Done color="primary" />
                                  ) : (
                                    <Close style={{ color: "#E44C5F" }} />
                                  )}
                                </TableCell>
                                <TableCell>
                                  {toTitleCase(row?.status)}
                                </TableCell>
                                <TableCell>
                                  {moment(row?.createdAt).format("YYYY-MM-DD")}
                                </TableCell>
                                <TableCell>
                                  {!row?.hasJoined && (
                                    <Button
                                      id="button-delete"
                                      style={{
                                        color: "#E44C5F",
                                        textTransform: "none",
                                      }}
                                      onClick={() => {
                                        setDeleteModalOpen(true);
                                        setDeleteId(row?._id);
                                      }}
                                    >
                                      <DeleteOutline color="error"></DeleteOutline>
                                      Delete
                                    </Button>
                                  )}
                                  {!row?.hasJoined &&
                                    row?.status === "expired" && (
                                      <Button
                                        id="resend-button"
                                        className="text-normal"
                                        onClick={() => {
                                          setUpdateId(row?._id);
                                          setResendConfirmModal(true);
                                        }}
                                      >
                                        <SendIcon
                                          sx={{ paddingRight: "4px" }}
                                          fontSize="small"
                                        />
                                        Resend
                                      </Button>
                                    )}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </Grid>
                </Grid>
              )}
            </TableListCard>
          </div>
        )}
      </Grid>
    </div>
  );
}
