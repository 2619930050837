import React, { useEffect, useState } from "react";
import { ColorPalette } from "../../../../../constants/colorPalette";
import {
  Background,
  Controls,
  ReactFlow,
  ReactFlowProvider,
  useEdgesState,
  useNodesState,
} from "@xyflow/react";
import CustomModal from "../../../../../components/CustomModal/CustomModal";
import {
  Button,
  Grid,
  IconButton,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import LastPageOutlinedIcon from "@mui/icons-material/LastPageOutlined";
import { toast } from "react-toastify";
import { getKeyFromDate } from "../../helper";
import moment from "moment";
import { Info } from "@mui/icons-material";
import "@xyflow/react/dist/style.css";

const EditMetricCalculationModal = ({
  metricId,
  setMetricId,
  spreadsheetData,
  setSpreadSheetData,
  handleEditAccountMetric,
  handleAddAccountMetric,
  sideNavData,
  editForecastMethods,
  setSideNavOpen,
}) => {
  const [nodes, setNodes] = useNodesState(null);
  const [edges, setEdges] = useEdgesState(null);
  const [pendingEdges, setPendingEdges] = useState([]);
  const [contextMenu, setContextMenu] = useState(null);

  useEffect(() => {
    if (metricId) {
      handleEditMetricCalculation(metricId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metricId, spreadsheetData]);

  const handleElementClick = (event, node) => {
    if (node.id && node.child) {
      handleEditAccountMetric(node.id);
    }
  };

  //handle the logic for nodes in edit account metric calculation modal
  const handleEditMetricCalculation = (rowId) => {
    const nodes = [];
    const edges = [];
    const VERTICAL_SPACING = 80;
    const BASE_X_POSITION = 230;

    const createNodeStyle = () => ({
      border: `1px solid ${ColorPalette.primary}`,
    });

    const calculateNodePosition = (index, totalNodes) => ({
      x: BASE_X_POSITION,
      y: -((totalNodes - 1) * VERTICAL_SPACING) / 2 + index * VERTICAL_SPACING,
    });

    const createNode = ({
      id,
      label,
      position,
      parentId = null,
      draggable = false,
      child = false,
    }) => {
      const maxLabelLength = 20; // Adjust this value to set the maximum length before truncation
      const isLongName = label.name.length > maxLabelLength;
      const truncatedName = isLongName
        ? `${label.name.slice(0, maxLabelLength)}...`
        : label.name;

      return {
        id,
        data: {
          label: (
            <>
              <Tooltip
                title={isLongName ? label.name : ""}
                placement="top-start"
              >
                <strong>{truncatedName}</strong>
              </Tooltip>

              <br />
              {label.calculationType && (
                <span
                  style={{
                    color: "#2196F3",
                    fontStyle: "italic",
                  }}
                >
                  calculation: {label.calculationType}
                </span>
              )}
              {label.metricType && (
                <span
                  style={{
                    color: "#2196F3",
                    fontStyle: "italic",
                  }}
                >
                  metric type: {label.metricType}
                </span>
              )}

              <br />
              <div
                style={{
                  marginTop: "4px",
                }}
              >
                {label.value}
              </div>
            </>
          ),
        },
        position,
        ...(parentId && { parentId }),
        draggable,
        style: createNodeStyle(),

        ...(child && { child }),
        sourcePosition: "right",
        targetPosition: "left",
      };
    };

    const createEdge = (source, target) => ({
      id: `${target}_edge`,
      source,
      target,
    });

    const getNodeLabel = (node) => {
      const obj = {};
      const filteredNode = spreadsheetData.value.find(
        (row) => row.id === node.id
      );
      if (node?.metricType === "Calculation") {
        const calcValue =
          filteredNode[getKeyFromDate(moment(filteredNode.startdate))];
        obj.name = node?.metricName;
        obj.calculationType = node.calculationType;
        obj.value = `$${calcValue.value}`;
      } else {
        obj.name = node?.metricName;
        obj.value =
          node.valueType === "Percentage"
            ? `${node?.initialValue}%`
            : `$${node?.initialValue}`;
      }
      obj.metricType = node.metricType !== "Calculation" && node.metricType;
      return obj;
    };

    // Modified to process only direct children of the selected calculation
    const processNode = (item, parentNodeId) => {
      if (!item.formulaId?.length) return;

      item.formulaId.forEach((fId, index) => {
        const childNode = spreadsheetData?.value.find((row) => fId === row?.id);
        if (!childNode) return;

        const position = calculateNodePosition(index, item.formulaId.length);

        nodes.push(
          createNode({
            id: childNode.id,
            label: getNodeLabel(childNode),
            position,
            parentId: parentNodeId,
            draggable: false,
            child: true,
          })
        );

        edges.push(createEdge(parentNodeId, childNode.id));

        // Only process child nodes if the current node is a calculation
        if (childNode.metricType === "Calculation") {
          processNode(childNode, childNode.id);
        }
      });
    };

    // Modified to show only unconnected nodes
    const processExtraNodes = () => {
      if (!nodes.length) return;

      const filteredMetrics = sideNavData.accountMetrics.slice(1).flat();

      // Get the maximum Y position from existing nodes
      const maxY = Math.max(...nodes.map((node) => node.position.y));
      const startingY = maxY + VERTICAL_SPACING * 1.2;
      const GRID_COLUMNS = 3;
      const HORIZONTAL_SPACING = 200;

      // Show all metrics that are not connected to any calculation
      const extraNodes = spreadsheetData.value.filter(
        (row) =>
          filteredMetrics.includes(row.id) &&
          row.metricType !== "Calculation" &&
          !spreadsheetData.value.some(
            (calcNode) =>
              calcNode.metricType === "Calculation" &&
              calcNode.formulaId?.includes(row.id)
          )
      );

      extraNodes.forEach((node, index) => {
        const position = {
          x: (index % GRID_COLUMNS) * HORIZONTAL_SPACING,
          y: startingY + Math.floor(index / GRID_COLUMNS) * VERTICAL_SPACING,
        };

        nodes.push(
          createNode({
            id: node.id,
            label: getNodeLabel(node),
            position,
            draggable: true,
            child: true,
            parentId: null,
          })
        );
      });
    };

    const initializeParentNode = () => {
      const parentNode = spreadsheetData?.value.find(
        (row) => row.metricType === "Calculation" && rowId === row.id
      );
      if (!parentNode) return null;

      const parentId = parentNode.id;
      nodes.push(
        createNode({
          id: parentId,
          label: getNodeLabel(parentNode),
          position: { x: 0, y: 0 },
        })
      );
      return { parentNode, parentId };
    };

    const parent = initializeParentNode();
    if (parent) {
      processNode(parent.parentNode, parent.parentId);
      processExtraNodes();
      setNodes(nodes);
      setEdges(edges);
    }
  };

  const handleConnect = (params) => {
    if (!params?.source || !params?.target) return;

    // Find source and target nodes with null checks
    const sourceNode = nodes?.find((n) => n.id === params.source);
    const targetNode = nodes?.find((n) => n.id === params.target);

    if (!sourceNode || !targetNode) return;

    const sourceData = spreadsheetData?.value?.find(
      (row) => row.id === sourceNode.id
    );
    const targetData = spreadsheetData?.value?.find(
      (row) => row.id === targetNode.id
    );

    // Check if trying to connect metric to metric
    if (
      sourceData.metricType !== "Calculation" &&
      targetData.metricType !== "Calculation"
    ) {
      toast.error("Metric node cannot be connected with another metric node");
      return;
    }
    const newEdge = {
      id: `${params.target}_edge`,
      source: params.source,
      target: params.target,
    };

    setEdges((prevEdges) => [...prevEdges, newEdge]);
    setPendingEdges((prev) => [
      ...prev,
      {
        source: sourceData,
        target: targetData,
      },
    ]);
  };

  // Add save handler function
  const handleSave = () => {
    const updatedSpreadsheetData = spreadsheetData.value.map((row) => {
      const edgeConnections = pendingEdges.filter(
        (edge) => edge.source.id === row.id && row.metricType === "Calculation"
      );

      if (edgeConnections.length > 0) {
        return {
          ...row,
          formulaId: [
            ...(row.formulaId || []),
            ...edgeConnections.map((edge) => edge.target.id),
          ],
        };
      }
      return row;
    });

    try {
      setSpreadSheetData((prev) => ({
        ...prev,
        value: updatedSpreadsheetData,
      }));

      toast.success("Spreadsheet updated metric to its parent node ");
      setPendingEdges([]);
    } catch (error) {
      toast.error("Failed to update spreadsheet");
    }
  };

  const handleCancel = () => {
    const filteredEdges = edges?.filter((row) => {
      for (let i = 0; i < pendingEdges.length; i++) {
        if (
          row.source === pendingEdges[i].source.id &&
          row.target === pendingEdges[i].target.id
        ) {
          return false;
        }
      }
      return true;
    });

    setEdges(filteredEdges);
    setPendingEdges([]);
  };

  // Add edge click handler
  const handleEdgeClick = (event, edge) => {
    // Remove edge from edges state
    setEdges((prevEdges) => prevEdges.filter((e) => e.id !== edge.id));

    const sourceNode = nodes.find((n) => n.id === edge.source);
    const targetNode = nodes.find((n) => n.id === edge.target);

    const updatedSpreadsheetData = spreadsheetData.value.map((row) => {
      if (row.id === sourceNode?.id && row.metricType === "Calculation") {
        return {
          ...row,
          formulaId: row.formulaId.filter((id) => id !== targetNode?.id),
        };
      }
      return row;
    });

    try {
      setSpreadSheetData((prev) => {
        return {
          ...prev,
          value: updatedSpreadsheetData,
        };
      });

      setPendingEdges((prevPendingEdges) =>
        prevPendingEdges.filter(
          (pe) =>
            !(pe.source.id === edge.source && pe.target.id === edge.target)
        )
      );
      toast.success("Connection deleted successfully");
    } catch (error) {
      toast.error("Failed to delete connection");
    }
  };

  //handle right click on node
  const handleContextMenu = (event, node) => {
    event.preventDefault();
    setContextMenu({
      x: event.clientX,
      y: event.clientY,
      node: node,
    });
  };

  const handleCloseContextMenu = () => {
    setContextMenu(null);
  };

  const openForecastModal = () => {
    const metric = spreadsheetData.value.find(
      (val) => contextMenu.node.id === val.id
    );
    editForecastMethods.setCurrentAccountMetricForecastId(metric.id);
    editForecastMethods.setCurrentAccountMetricData(metric);
    editForecastMethods.handleAddForecast(metric.parentId);
    handleCloseContextMenu();
  };

  return (
    <div>
      <CustomModal
        modalOpen={metricId}
        setModalOpen={handleEditMetricCalculation}
        onClose={() => setMetricId(null)}
        overflow={"auto"}
        maxWidth={"md"}
      >
        <div>
          <Grid container padding={2}>
            <Grid
              container
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Grid
                item
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                gap={1}
              >
                <Typography variant="h5">
                  Edit Account Metric Calculation
                </Typography>
                <Tooltip title="The value of calculation and metrics is the initial value of the first month of the start date.">
                  <Info />
                </Tooltip>
              </Grid>
              <Grid item>
                <IconButton
                  onClick={() => {
                    setMetricId(null);
                    setSideNavOpen(true);
                  }}
                >
                  <Tooltip title="Redirect to side navigation">
                    <LastPageOutlinedIcon />
                  </Tooltip>
                </IconButton>
                <IconButton onClick={() => setMetricId(null)}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Grid container py={3}>
              <div style={{ width: "100%", height: "400px" }}>
                <ReactFlowProvider>
                  <ReactFlow
                    nodes={nodes || []}
                    edges={edges || []}
                    fitView
                    attributionPosition="top-right"
                    multiSelectionKeyCode={null}
                    zoomOnScroll={false}
                    connectOnClick={false}
                    onConnect={handleConnect}
                    onNodeClick={handleElementClick}
                    onEdgeClick={handleEdgeClick}
                    onNodeContextMenu={handleContextMenu}
                  >
                    {contextMenu && (
                      <div
                        style={{
                          position: "fixed",
                          top: contextMenu.y,
                          left: contextMenu.x,
                          background: "#f6f6f6",
                          boxShadow: "0px 4px 10px rgba(0,0,0,0.2)",
                          borderRadius: "4px",
                          zIndex: 1000,
                          padding: "8px 0",
                        }}
                      >
                        <Grid container display={"flex"} alignItems={"center"}>
                          <MenuItem
                            onClick={() => {
                              openForecastModal();
                            }}
                          >
                            Forecast Timing
                          </MenuItem>
                          <IconButton>
                            <CloseIcon onClick={handleCloseContextMenu} />
                          </IconButton>
                        </Grid>
                      </div>
                    )}
                    <Controls
                      showZoom={true}
                      showFitView={true}
                      position="bottom-right"
                    />
                    <Background />
                  </ReactFlow>
                </ReactFlowProvider>
              </div>
            </Grid>
            <Grid
              container
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
              gap={3}
            >
              <Grid item>
                <Button variant="contained" onClick={handleAddAccountMetric}>
                  Add Node
                </Button>
              </Grid>
              <Grid item>
                <Grid
                  container
                  display={"flex"}
                  justifyContent={"right"}
                  gap={2}
                >
                  <Button
                    variant="outlined"
                    onClick={handleCancel}
                    disabled={pendingEdges.length === 0}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleSave}
                    disabled={pendingEdges.length === 0}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </CustomModal>
    </div>
  );
};

export default EditMetricCalculationModal;
